import * as React from 'react';

import Proptypes from 'prop-types';
import { css } from "@emotion/core";
import { injectStripe } from 'react-stripe-elements';
import CardSection from './card';
import Spinner from '../spinner/spinner';

class StripeForm extends React.Component {
  static propTypes = {
    stripe: Proptypes.shape({
      createToken: Proptypes.func.isRequired,
    }).isRequired,
    onSubmit: Proptypes.func.isRequired,
    onError: Proptypes.func.isRequired,
    onReady: Proptypes.func,
    formStatus: Proptypes.node,
    children: Proptypes.node,
    button: Proptypes.string,
  };

  static defaultProps = {
    formStatus: null,
    children: null,
    button: 'Submit',
    onReady: () => null,
    onReady: () => null,
  };

  state = {
    loading: true,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.stripe
      .createToken()
      .then(this.props.onSubmit)
      .catch(this.props.onError);
  };

  onReady = e => {
    this.setState({ loading: false });
  };

  onError = e => {
    this.setState({ loading: false });
  };

  render() {
    this.props.attachForm(this);
    // ref={this.props.attachForm.bind(this)}
    return (
      <React.Fragment>
        <Spinner active={this.state.loading} />
        <form
          css={css`
            display: ${this.state.loading ? 'none' : 'default'};
          `}
          onSubmit={this.handleSubmit.bind(this)}
          id="submitCard"
        >
          {this.props.formStatus}
          <CardSection onReady={this.onReady} onError={this.onError} />
          {this.props.children}
        </form>
      </React.Fragment>
    );
  }
}

export default injectStripe(StripeForm);
