import * as React from 'react';
import { css } from "@emotion/core";

export default ({ error, success }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      margin: 15px 0;
      height: 20px;
      color: ${error ? 'red' : 'green'};
    `}
  >
    <span>
      {error}
      {success}
    </span>
  </div>
);
