import * as React from 'react';
import { css } from "@emotion/core";
import { api } from '../../../config';
import axios from 'axios';
import { push, graphql } from 'gatsby';
import {Link} from "@fitplan/lib/components/Link";

import {
  isLoggedIn,
  getUser,
  updateUser,
  getAccessToken,
} from '../../../utils/auth';

import PageWrapper from "../../../components/pageWrapper/PageWrapper";
import Spinner from "../../../components/spinner/spinner";
import AccountLayout from '../account-layout';
import InternalPage from '../internal-page';
import Stripe from '../../../components/stripe/stripe';
import FormStatus from '../../../components/form/formStatus';
import Card from '../card';
import CreditCardIcon from '../credit-card-icon';
import localize from '../../../hoc/Localize';

class Payment extends React.Component {
  state = {
    user: getUser(),
    error: null,
    success: null,
    loading: null,
  };

  attachForm = node => {
    this._form = node;
  };

  onSubmit = ({ token, error }) => {
    const _self = this;
    if (error)
      return this.setState({ loading: false, error: error.message }, () => {
        _self.props.updateStatus({
          cancelSuccess: '',
          cancelFailure: error.message,
        });
      });
    this.setState({ loading: true });

    axios({
      method: 'post',
      url: `${api.base}${api.updatePaymentMethod}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        token: token.id,
      },
    })
      .then(res => {
        if (res.data.error) {
          return this.setState(
            {
              error: this.props.profile.updatePaymentFail,
              loading: false,
            },
            () => {
              _self.props.updateStatus({
                cancelSuccess: '',
                cancelFailure: this.props.profile.updatePaymentFail,
              });
            }
          );
        }

        this.setState(
          {
            success: this.props.profile.updatePaymentSuccess,
            error: null,
            loading: false,
          },
          () => {
            _self.props.updateStatus({
              cancelSuccess: this.props.profile.updatePaymentSuccess,
              cancelFailure: '',
            });
          }
        );
      });
  };

  onError = err => {
    this.setState({
      success: this.props.profile.updatePaymentSuccess,
      error: 'null',
      loading: false,
    });
  };

  render() {
    const { error, success } = this.state;
    const { account } = this.props.data.sanityAccount;
    return (
      <PageWrapper>
        <AccountLayout currentPage="/account/subscription">
          <InternalPage titleText={account.changePaymentDetails} toast={''}>
            <Card className="wide_card">
              <div
                css={css`
                  /* white-space: nowrap; */
                  display: block;
                  text-align: center;
                  font-family: league-gothic, sans-serif;
                  font-size: 20px;
                  letter-spacing: normal;
                  margin: 0px;
                `}
              >
                <div
                  css={css`
                    height: 40px;
                    font-family: Barlow;
                    font-size: 32px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: condensed;
                    line-height: 1.25;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;
                    padding-bottom: 40px;
                  `}
                >
                  {account.enterCreditCard}
                </div>
                <div
                  css={css`
                    display: block;
                    margin: auto;
                    max-width: 500px;
                    @media (max-width: 767px) {
                      flex-direction: column;
                    }
                  `}
                >
                  <Spinner active={this.state.loading} />

                  <Stripe
                    formStatus={<FormStatus error={error} success={success} />}
                    button={account.changePaymentDetails}
                    onSubmit={this.onSubmit}
                    onError={this.onError}
                    attachForm={this.attachForm}
                  />
                  <div
                    css={css`
                      display: flex;

                      @media (max-width: 767px) {
                        flex-direction: column;
                      }
                    `}
                  >
                    <button
                      className={'header__nav-button button-hover'}
                      css={css`
                        margin-top: 12px;
                        min-width: 250px;
                        height: 63.6px;
                      `}
                      onClick={e => {
                        this._form.handleSubmit(e);
                      }}
                    >
                      {account.changePaymentDetails}
                    </button>
                    <Link
                      to="/account/subscription"
                      css={css`
                        width: 250px;
                        margin-top: 12px;
                        height: 63.6px;

                        box-shadow: none;
                        background-image: none;
                        color: black;
                        font-family: Barlow;
                        font-size: 21px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: condensed;
                        line-height: 63px;
                        letter-spacing: normal;
                        text-align: center;
                        border: none;
                        display: table;
                        &:hover {
                          cursor: pointer;
                        }
                        @media (max-width: 767px) {
                          order: 2;
                          width: 100%;
                        }
                      `}
                    >
                      {account.subscriptionCancelButton.cancelButton}
                    </Link>
                  </div>
                </div>
              </div>
            </Card>
          </InternalPage>
        </AccountLayout>
      </PageWrapper>
    );
  }
}

export const query = graphql`
  query paymentQuery {
    sanityAccount {
      account {
        changePaymentDetails {
          _type
          en
          es
        }
        enterCreditCard {
          _type
          en
          es
        }
        subscriptionCancelButton {
          cancelButton {
            _type
            en
            es
          }
        }
      }
    }
  }
`;

export default localize(Payment);
